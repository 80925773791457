<template>
	<div class="outer-wrapper">
		<div class="fixed-top outer-wrapper">
			<router-link :to="{ 
				name: 'Tours Keyword',
			}">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
			<div class="trip-type-holder">
				<span class="title">Tour Search</span>
			</div>
		</div>
		
		<div class="inner-wrapper main">
			<div class="toggle-area">
				<span class="mode-toggle" @click="toggleSelectMode();">
					{{ showSpecificDates ? 'Select months' : 'Select specific dates' }} 
					<img v-if="showSpecificDates" width="19px" src="@/assets/tour-search/month-calendar-icon.png"/>	
					<img v-else width="19px" src="@/assets/tour-search/range-calendar-icon.png"/>	
				</span>
			</div>
			<div class="selected-dates">
				<div class="depart date-section">
					Start after
					<div class="date">
						{{ formatDate(formattedDates.start) }}
					</div>
				</div>
				<div class="return date-section">
					Finish by
					<div class="date">
						{{ formatDate(formattedDates.end) }}
					</div>
				</div>
				<div style="clear: both"></div>
			</div>
		</div>

		<div class="inner-wrapper tour picker" v-if="showSpecificDates">
			<v-date-picker 
				@input="selectAllDates = false;"
				class="datepicker"
				v-model="toursSearch.searchValues.dates"
				mode="date" 
				:is-range="true"
				color="customblue"
				:min-date="new Date()"
				trim-weeks
				:step="1"
			>
				<!-- <a slot='header-left-button'></a> Hide the left/right arrows -->
				<!-- <a slot='header-right-button'></a> -->
			</v-date-picker>
		</div>

		<div class="inner-wrapper month-picker" v-else-if="!showSpecificDates">
			<!-- Output slider where each slide is a year, and each slide contains all months -->
			<swiper ref="monthSwiper" class="month-swiper" 
				:options="swiperOptions"
			>
				<div class="swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-pagination" slot="pagination"></div>
				<div class="swiper-button-next" slot="button-next"></div>
				<swiper-slide v-for="(e, index) in 5" :key="index"> <!-- 5 years slides from current year -->
					<div class="months">
						<span v-for="monthIndex in 12" :key="monthIndex" class="month" 
							:class="{ 
								'selected': isSelected(currentYear + index, monthIndex),
								'not-selectable': index == 0 
									&& currentMonth >= monthIndex }" 
							@click="toggleMonth(currentYear + index, monthIndex)">
							{{ toMonthName(monthIndex) }}
						</span>
					</div>
				</swiper-slide>
			</swiper>

			<!-- Hacky way to force update - swiper doesn't update when data changes for some reason.  Hidden area.-->
			<div class="hidden">
				<input type="checkbox" id="update" v-model="update">
				<label for="update"></label>
			</div>

		</div>

		<div class="inner-wrapper select-all">
			<Toggle class="toggle-class" text="Select all dates" v-model="selectAllDates" @input="toggleSelectedAllDates()"/>
		</div>

		<button class="button continue" v-on:click="submit()" :class="{ disabled: !checkForm }">Continue</button>
	</div>
</template>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex'
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';
	import Toggle from '@/components/Toggle.vue';
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'

	export default {
		name: 'toursSearchDates',
		data() {
			return {
				errors: new Map(),

				currentYear: new Date().getFullYear(),
				currentMonth: new Date().getMonth(),
				update: false, // Hacky way to force update - swiper doesn't update when data changes for some reason.
				showSpecificDates: false, // Toggle between month picker and date picker

				months: new Array(), // Array contain the first [0] and last [1] select month in the months selecter
				selectAllDates: false, // All dates should be selected

				// Months swiper options
				swiperOptions: {
					slidesPerView: "auto",
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					pagination: {
						el: ".swiper-pagination",
						renderBullet: function (index, className) {
							let currentYear = new Date().getFullYear();
							return '<span class="' + className + '">' + (currentYear + index) + "</span>";
						},
					},
				},
			}
		},
		components: {
			Swiper,
   			SwiperSlide,
			Toggle,
		},
		directives: {
			swiper: directive
		},
		computed: {
			...mapState({
				toursSearch: state => state.toursSearch,
			}),
			dates() {
				return this.toursSearch.searchValues.dates; // Mapped to the state
			},
			...mapGetters('toursSearch', ['formattedDates']),
			checkForm() {
				this.errors = new Map();
				let dateErrors = [];
				if(this.formattedDates.start == null || this.formattedDates.start == "") {
					dateErrors.push('You must select a start date');
				}
				if(this.isReturn) {
					if(this.formattedDates.end == null || this.formattedDates.end == "") {
						dateErrors.push('You must select a finish date');
					}
				}
				if(dateErrors.length > 0) {
					this.errors.set('dates', dateErrors);
				}
				if (!this.errors.size > 0) {
					return true;
				}
				return false;
				e.preventDefault();
			},
		},
		methods: {
			...mapActions({
				mapQueryParams: 'toursSearch/mapQueryParams',
			}),
			toMonthName: (monthIndex) => helpers.toMonthName(monthIndex),
			formatDate: (date) => helpers.formatDate(date),
			toggleSelectedAllDates() { // This is triggered on the toggle button. If turning the toggle on, set the dates to the first and last day of the selected month. If turning the toggle off, set the dates to null.
				let now = new Date();
				console.log("hit");
				console.log(this.selectAllDates);

				if(this.selectAllDates) {
					this.showSpecificDates = false; // If we are selecting all dates, we don't want to show the specific dates. Change to month view. This is actually required, as I can't get things to work properly otherwise. Major PITA bug when this isn't the case.
					// Set the months to the first and last possible months
					this.months[0] = { year: now.getFullYear(), month: now.getMonth() + 1}; // Set to the current month and year
					this.months[1] = { year: now.getFullYear() + 4, month: 12}; // Add 5 years to the current year, and set the month to December (11)
					
				} else {
					this.months = new Array(); // Reset the months array
					this.months[0] = { year: now.getFullYear(), month: now.getMonth() + 1};
				}

				this.setDatesByMonths();
			},
			submit() {
				if(this.checkForm) {
					router.push({
						name: 'Tours Search',
					})
				}
			},
			toggleSelectMode() {
				this.showSpecificDates = !this.showSpecificDates;
				this.selectAllDates = false;
				if(!this.showSpecificDates) {
					// If switching to the month view from the specific view, set the dates, and the months variable to the first and last day of the selected specific dates.
					this.setMonthsByDates();
				} else {
					this.setDatesByMonths();
				}
			},
			toggleMonth(year, month) {
				// Ensure the selectAllDates toggle is now false;
				this.selectAllDates = false;
				// Assign the first and last month selected based on the current values, to enable it to act as a range selector
				if(this.months.length == 0) {
					this.months.push({ year: year, month: month });
				} else if(this.months.length == 1) {
					if(this.months[0].year == year && this.months[0].month == month) {
						this.months = [];
					} else {
						this.months.push({ year: year, month: month });
					}
				} else {
					this.months = [];
					this.months.push({ year: year, month: month });
				}

				// Order months by date
				if(this.months.length == 2) {
					let firstMonth = new Date(this.months[0].year, this.months[0].month);
					let secondMonth = new Date(this.months[1].year, this.months[1].month);
					if(firstMonth > secondMonth) {
						this.months = [this.months[1], this.months[0]];
					}
				}
				this.update = !this.update; // Hacky way to force update - swiper doesn't update when data changes for some reason.

				// Update the vuex state dates
				this.setDatesByMonths();
			},
			setMonthsByDates() { // Uses the vuex state dates to set the months array to the first and last month selected
				let firstDate =  new Date(this.toursSearch.searchValues.dates.start);
				let lastDate =  new Date(this.toursSearch.searchValues.dates.end);
				let updatedFirstDate = new Date(firstDate.getFullYear(), firstDate.getMonth(), 1);
				let updatedLastDate = new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 0);
				this.toursSearch.searchValues.dates = { // Update the dates in the store to the first and last day of the selected months
					start: updatedFirstDate, 
					end: updatedLastDate
				};

				// Update the months array to the first and last month selected
				this.months[0] = { year: updatedFirstDate.getFullYear(), month: updatedFirstDate.getMonth() + 1 };
				this.months[1] = { year: updatedLastDate.getFullYear(), month: updatedLastDate.getMonth() + 1 };
			},
			setDatesByMonths() {
				if(this.months.length == 2) { // Month range
					this.toursSearch.searchValues.dates = {
						start: new Date(this.months[0].year, this.months[0].month - 1, 1), // First day of first month selection
						end: new Date(this.months[1].year, this.months[1].month, 0), // Last day of last month selection (month + 1 to get next month, then 0 to get last day of previous month)
					}
				} else if(this.months.length == 1) { // Just a single month
					this.toursSearch.searchValues.dates = {
						start: new Date(this.months[0].year, this.months[0].month - 1, 1), // First day of first month (month is 0 indexed)
						end: new Date(this.months[0].year, this.months[0].month, 0), // Last day of FIRST month (month + 1 to get next month, then 0 to get last day of previous month)
					}
				} else {
					// this.toursSearch.searchValues.dates = null;
					this.toursSearch.searchValues.dates.start = new Date();
				}
				let today = new Date();
				if(this.toursSearch.searchValues.dates.start < new Date()) { // If the start date is in the past, set it to today
					this.toursSearch.searchValues.dates.start = today;					
				}
			},
			isSelected(year, month) { // Checks if the calendar month is selected (for styling) based on the current months array selection
				this.months;
				if(!this.months[0]) { // No months set;
					return false; 
				}
				if(!this.months[1]) { // Only one month set
					// Check first month
					if(this.months[0].year == year && this.months[0].month == month) { // If the selection is exactly the same
						return true;
					}
				} else { // Both months set
					if(this.months[1].year == this.months[0].year && year == this.months[0].year) { // Both selections are in the same year
						if(month >= this.months[0].month && month <= this.months[1].month) { // and the month is between the two selections
							return true;
						}
						return false;
					}
					if(this.months[0].year == year) { // If the year is the same as the first selection
						if(this.months[0].month <= month) { // and the month is after the first selection
							return true;
						}
						return false;
					}
					if(this.months[1].year == year) { // If the year is the same as the second selection
						if(this.months[1].month >= month) { // and the month is before the second selection
							return true;
						}
						return false;
					}
					if(this.months[0].year < year && this.months[1].year > year) { // If the year is between the first and second selections
						return true; // Always true
					}
				}
				return false; // No condition hit.
			}
		},
		created () {
			this.mapQueryParams();

			// Ensure the user can see all valid dates by resetting to current day (temporarily) // This is hacky - is there a better way? 
			var selectedDate = this.toursSearch.searchValues.dates; // Store selected data temporarily
			this.toursSearch.searchValues.dates = new Date();
			setTimeout(() => {
				this.toursSearch.searchValues.dates = selectedDate;
				this.setMonthsByDates();
			}, 100)
		
			this.toursSearch.shouldSearch = true;
		},
	}
</script>

<style> 
	/* Overrides of the Toggle styles */
	.select-all .toggle-class {
		width: 100%;
		margin-top: 30px;
	}
	.select-all .toggle-class .text {
		font-size: 16px;
		line-height: 24px;
		color: #50555C;
	}
	.select-all .toggle-class .round-slider {
		position: absolute;
		right: 0;
	}
	/* Overrides of the swiper styles */
	.month-swiper .swiper-container {
		padding-top: 30px;
	}
	.month-swiper .swiper-pagination {
		top: 0px;
		bottom: unset;
		left: unset;
		font-size: 16px;
		font-weight: 600;
		color: black;
	}
	.month-swiper .swiper-pagination .swiper-pagination-bullet {
		display: none;
	}
	.month-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
		display: inline-block;
		background: none;
		width: auto;
		height: auto;
	}
	.month-swiper .swiper-button-next,
	.month-swiper .swiper-button-prev {
		top:0px;
		height: 16px;
		width: 16px;
		left: unset;
		right: unset;
		background-size: 8px;
		background-position: left 50% bottom 1px;
		background-repeat: no-repeat;
		margin: 0;
	}
	.month-swiper .swiper-button-next {
		right: 10px;
		background-image: url("../../../assets/tour-search/arrow-right.png");
	}
	.month-swiper .swiper-button-prev {
		left: 10px;
		background-image: url("../../../assets/tour-search/arrow-right.png");
		transform: rotate(180deg);
		background-position: left 50% top 0px;
	}
	.month-swiper .swiper-button-next:after,
	.month-swiper .swiper-button-prev:after {
		content: '';
	}

	/* Override of vcalendar styles */
	.tour .picker .datepicker {
		width: 100%;
	}
</style>

<style scoped>
	.inner-wrapper {
		max-width: 300px;
		margin: 0 auto;
	}
	.trip-type-holder {
		position: relative;
		text-align: center;
	}
	.title {
		width: 125px;
		display: inline-block;
		color: #118AB2;
		font-weight: bold;
		font-size: 20px;
	}
	.dates {
		/* min-height: calc(100vh - 85px); */
		position: relative;
	}
	.fixed-bottom {
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 100%;
		z-index: 10;
		background-color: white;
	}
	
	.main {
		/* width: 300px; */
		margin: 0px auto;
		margin-bottom: 10px;
		margin-top: 100px;
	}
	.main .toggle-area {
		width: 100%;
		height: 30px;
	}
	.main .mode-toggle {
		float: right;
		font-size: 14px;
		line-height: 17px;
		color: #118AB2;
		vertical-align: middle;
		cursor: pointer;
	}
	.main .mode-toggle img {
		margin-left: 5px;
		vertical-align: middle;
	}
	.selected-dates {
		margin-top: 30px;
	}
	.selected-dates .date-section {
		display: inline-block;
		color: #50555C;
		font-size: 12px;
	}
	.selected-dates .date-section .date {
		font-size: 16px;
		font-weight: bold;
		color: black;
		margin-top: 10px;
	}
	.selected-dates .date-section.depart {
		float: left;
		text-align: left;
	}
	.selected-dates .date-section.return {
		float: right;
		text-align: right;
	}
	.picker {
		max-height: calc(100vh - 215px);
		overflow-y: hidden;
	}
	.datepicker {
		border: none;
		display: block;
		margin: 0 auto;
	}

	.button {
		display: block;
		margin: 0 auto
	}

	.hidden {
		display: none;
	}
	.month-swiper {
		max-width: 300px;
		padding-top: 30px;
	}
	.month-picker {
		margin-top: 25px;
	}
	.month-swiper .months {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 15px;
		row-gap: 15px;
		
	}
	.month-swiper .month {
		width: 84px;
		line-height: 55px;
		height: 55px;
		display: inline-block;
		text-align: center;
		border: 1px solid #E5E5E5;
		border-radius: 10px;
		color: #50555C;
		font-size: 16px;
		cursor: pointer;
	}
	.month-swiper .month.not-selectable {
		opacity: 0.5;
		cursor: not-allowed;
		pointer-events: none;
	}
	.month-swiper .month.selected {
		border: 1px solid #118AB2;
		background-color: #118AB2;
		color: #fff;
		font-weight: bold;		
	}

	.continue.button {
		margin-top: 20px;
	}
	
</style>